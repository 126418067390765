import React, { useState, useEffect, useRef, useContext } from "react";
import styled from "styled-components";
import gsap from 'gsap/gsap-core'
import { OpenFormContext } from '@components/Layout'
import loadable from '@loadable/component'

import useMedia from '@hooks/useMedia'

import colors from "@styles/colors";
import media from "@styles/media";
import text from "@styles/text";

import { StaticImage } from "gatsby-plugin-image";

const PrimaryButton = loadable(() => import("@components/PrimaryButton"))
const TextAnimation = loadable(() => import('@components/TextAnimation'))

type props = {
  loading: boolean
}

const PartnerCTA: React.FC<props> = ({loading}) => {
  const [triggerTitle, setTriggerTitle] = useState(false)

  const openForm = useContext(OpenFormContext)

  const wrapperRef = useRef(null)
  const buttonWrapperRef = useRef(null)
  const imgRef = useRef(null)

  const tlStart = useMedia("top+=100 top", "top+=100 top", "top-=120% top")
  const imgZoomStart = useMedia("top+=100 top", "top+=100 top", "op-=120% top")

  useEffect(() => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: wrapperRef.current,
          start: tlStart,
        }
      })
      tl.to(buttonWrapperRef.current, {
        duration: 1,
        opacity: 1
      })
      tl.call(setTriggerTitle, [true], 0)
  
      const imgZoom = gsap.timeline({
        scrollTrigger: {
          trigger: wrapperRef.current,
          start: imgZoomStart,
          end: '+=100%',
          scrub: true
        }
      })
      imgZoom.to(imgRef.current, {
        scale: "1.2"
      })
  
      return () => {
        tl.kill()
        imgZoom.kill()
      }
    
  }, [])

  return (
    <Wrapper ref={wrapperRef}>
      <ImageWrapper>
        <Image ref={imgRef}>
          <StaticImage
            src={"../../images/jpg/doublePipes.jpg"}
            alt="pipeline"
            placeholder="blurred"
            style={{width: '100%', height: '100%'}}
          />
        </Image>
      </ImageWrapper>
      <TitleContainer>
        <Title>
          <TextAnimation
            textArray={[
              () => "Build a better future",
              () => "for your pipelines and", 
              () => "for the planet."
            ]}
            height={useMedia('5.556vw', '5.556vw', '4.317vw', '7.467vw')}
            className="partner-cta-title"
            trigger={triggerTitle}
          />
        </Title>
        <ButtonWrapper ref={buttonWrapperRef}>
          <PrimaryButton
            text="Partner with our Team"
            textColor={colors.black}
            backgroundColor={colors.keppel100}
            hoverColor={colors.keppel60}
            width={useMedia('fit-content', 'fit-content', 'fit-content', '100%')}
            onClick={() => openForm(true)}
          />
        </ButtonWrapper>
      </TitleContainer>
    </Wrapper>
  );
};

export default PartnerCTA;

const Wrapper = styled.section`
  height: 100%;
  width: 100%;
  background-color: ${colors.black};
  position: relative;
  overflow: hidden;
  position: relative;

  ${media.fullWidth} {
    height: 76.8vw;
  }

  ${media.desktop} {
    height: 76.8vw;
  }

  ${media.tablet} {
    height: 61vw;
    padding-bottom: 20.384vw;
  }

  ${media.mobile} {
    height: 195.667vw;
  }
`;

const Title = styled.h2`
  color: ${colors.culturedWhite60};
  display: flex;
  flex-direction: column;

  ${media.fullWidth} {
    ${text.desktopXLHeading}
    margin-bottom: 3.8vw;
  }

  ${media.desktop} {
    ${text.desktopXLHeading}
    margin-bottom: 3.8vw;
  }

  ${media.tablet} {
    ${text.tabletXSHeading}
    margin-bottom: 4.317vw;
  }

  ${media.mobile} {
    ${text.mobileXXSHeading}
    margin-bottom: 10.667vw;
  }
`;

const ImageWrapper = styled.div`
  position: absolute;
  left: 0;
  overflow: hidden;

  ${media.fullWidth} {
    width: 69.3vw;
    height: 59.8vw;
    top: 7.8vw;
  }

  ${media.desktop} {
    width: 69.3vw;
    height: 59.8vw;
    top: 7.8vw;
  }

  ${media.tablet} {
    width: 64.029vw;
    height: 58.993vw;
    top: 0vw;
  }

  ${media.mobile} {
    top: 0vw;
    width: 100vw;
    height: 106.133vw;
  }
`

const Image = styled.div`
  width: 100%;
  height: 100%;
`;

const TitleContainer = styled.div`
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: ${colors.black};

  ${media.fullWidth} {
    width: 65.1vw;
    height: 40.2vw;
    padding: 5.3vw 3.5vw 10.4vw 7.8vw;
  }

  ${media.desktop} {
    width: 65.1vw;
    height: 40.2vw;
    padding: 5.3vw 3.5vw 10.4vw 7.8vw;
  }

  ${media.tablet} {
    width: 56.595vw;
    height: 37.050vw;
    padding-top: 6.475vw;
    padding-left: 7.554vw;
    padding-bottom: 6.355vw;
    padding-right: 5.995vw;
    bottom: unset;
    top: 21.942vw;
  }

  ${media.mobile} {
    top: 106vw;
    width: 100%;
    padding-left: 6.667vw;
    padding-right: 6.667vw;
    padding-top: 13.333vw;
  }
`;

const ButtonWrapper = styled.div`
  opacity: 0;
`
